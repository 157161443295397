import { useState, useEffect } from "react";
import moment from 'moment';
function convertHoursToHMS(hours, upOrDown) {

    if (upOrDown === "down") {
        hours = Math.abs(hours);
    }

    const hoursRemaining = Math.floor(hours);
    const minutesRemaining = Math.floor((hours - hoursRemaining) * 60);
    const secondsRemaining = Math.floor((hours - hoursRemaining) * 3600 % 60);
    const result = {}

    if (hoursRemaining > 0) {
        result.hours = hoursRemaining;
    }
    
    if (minutesRemaining > 0) {
        result.minutes = minutesRemaining;
    }
    
    if (secondsRemaining > 0) {
        result.seconds = secondsRemaining;
    }

    let output = ''
    let totalDays = ''
    let remainingHours = ""
    if (result.hours) {
        if (result.hours > 24) {
            totalDays = parseInt(result.hours / 24)
            remainingHours = result.hours - (totalDays * 24)
            output += `${totalDays + " days " + remainingHours}:`;
        } else {
            output += `${result.hours}:`;
        }
        
    }

    if (result.minutes  >= 10) {
        output += result.minutes+":" ;
    } else if (result.minutes  >= 0) {
        output += `0${result.minutes }:`;
    } else if (result.minutes  === undefined) {
        output += `00:`;
    }

    if (result.seconds  >= 10) {
        output += result.seconds ;
    } else if (result.seconds  >= 0) {
        output += `0${result.seconds }`;
    } else if (result.seconds  === undefined) {
        output += `00`;
    }

    return {
        output 
    };
  }


function HeaderBlock(props) {
    const [raceTime, startData] = useState([]);




    const raceTimeCalc = (startTime) => {


        const currentDate = moment();
        const theStartDate  = moment(startTime, 'YYYYMMDDHHmmss')
        const clockDuration = moment.duration(theStartDate.diff(currentDate)).add(moment.duration(1, 'hours'))
        const totalHours = clockDuration.asHours();
        const hours = clockDuration.hours()
        
        if (totalHours > 0) {
            const timeParts = convertHoursToHMS(totalHours, "up");
            return ("The race starts in: "+ timeParts.output )
        } else if (totalHours < 0) {
            const timeParts = convertHoursToHMS(totalHours, "down");
            return ("Race time: " + timeParts.output)
        }

        if (hours < -4) {
            return ("The event has now finished.")
        }

        return;
    }

    useEffect(() => {
        startData(raceTimeCalc(props.raceTime)); 
        const interval = setInterval(() => {
            startData(raceTimeCalc(props.raceTime)); 
          }, 1000); 

          return () => clearInterval(interval); 
      }, [props.raceTime]);

    return (
        <header>
            <img src={props.logo} alt={props.header} className="logo"/>
            <h1>{props.header}</h1>
            
            {/* <p>Tap on a runner to see more info</p> */}
        </header>
    );
}

export default HeaderBlock;